import {mapActions, mapGetters} from "vuex";

import {VueSlideToggle} from "vue-slide-toggle";

export default {
  name: "order-accordion",
  props: {
    order: {
      type: Object,
      default: ()=> {}
    }
  },
  components: {
    VueSlideToggle
  },
  watch: {
    '$route'(newVal){
      if(newVal){

      }
    }
  },
  data(){
    return{
      open: false,
    }
  },
  created() {

  },
  computed:{
    ...mapGetters({
    }),

  },
  methods:{
    ...mapActions({
    }),
  }
}