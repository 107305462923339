import {mapActions, mapGetters} from "vuex";

import orderAccordion from "@/modules/profile/sections/components/order-accordion/index.vue";

export default {
  name: "orders",
  watch: {
    '$route'(newVal){
      if(newVal){
        this.fetchOrders()
      }
    }
  },
  components: {
    orderAccordion,
  },
  data(){
    return{

    }
  },
  created() {
    this.fetchOrders()
  },
  computed:{
    ...mapGetters({
      orderList: 'profile/orders'
    }),

  },
  methods:{
    ...mapActions({
      fetchOrders: 'profile/FETCH_ORDERS'
    }),
  }
}